<template>
    <div class="relative" v-on-click-outside="onClickOutside">
        <button class="text-white text-white-text bg-primary hover:bg-primary-focus font-semibold rounded-xl text-sm px-4 py-2.5 text-center inline-flex items-center disabled:opacity-70" :disabled="loading" type="button" @click="onClick()">
            <Loader :size="4" class="mr-2" v-if="loading" />
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none" stroke="var(--theme-color-white-text)" class="mr-2">
                <g clip-path="url(#clip0_4793_2292)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000244141 7.00039C0.000244141 6.39288 0.522579 5.90039 1.16691 5.90039H12.8336C13.4779 5.90039 14.0002 6.39288 14.0002 7.00039C14.0002 7.6079 13.4779 8.10039 12.8336 8.10039H1.16691C0.522579 8.10039 0.000244141 7.6079 0.000244141 7.00039Z" fill="var(--theme-color-white-text)" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000244141 2.40078C0.000244141 1.79327 0.522579 1.30078 1.16691 1.30078H12.8336C13.4779 1.30078 14.0002 1.79327 14.0002 2.40078C14.0002 3.00829 13.4779 3.50078 12.8336 3.50078H1.16691C0.522579 3.50078 0.000244141 3.00829 0.000244141 2.40078Z" fill="var(--theme-color-white-text)" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.000244141 11.6C0.000244141 10.9925 0.522579 10.5 1.16691 10.5H12.8336C13.4779 10.5 14.0002 10.9925 14.0002 11.6C14.0002 12.2075 13.4779 12.7 12.8336 12.7H1.16691C0.522579 12.7 0.000244141 12.2075 0.000244141 11.6Z" fill="var(--theme-color-white-text)" />
                </g>
                <defs>
                    <clipPath id="clip0_4793_2292">
                        <rect width="14" height="14" fill="white" transform="translate(0.000244141)" />
                    </clipPath>
                </defs>
            </svg>
            More Options
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="11" viewBox="0 0 16 11" fill="var(--theme-color-white-text)" stroke="var(--theme-color-white-text)" class="ml-2" :class="{ 'transform transition-all rotate-180': show }">
                <path d="M6.93958 6.93934L5.87892 8L8.00024 10.1213L9.0609 9.06066L6.93958 6.93934ZM15.0609 3.06066C15.6467 2.47487 15.6467 1.52513 15.0609 0.93934C14.4751 0.353553 13.5254 0.353553 12.9396 0.93934L15.0609 3.06066ZM9.0609 9.06066L15.0609 3.06066L12.9396 0.93934L6.93958 6.93934L9.0609 9.06066Z" fill="var(--theme-color-white-text)" />
                <path d="M6.93958 9.06066L8.00024 10.1213L10.1216 8L9.0609 6.93934L6.93958 9.06066ZM3.0609 0.939339C2.47512 0.353553 1.52537 0.353553 0.939583 0.93934C0.353797 1.52513 0.353797 2.47487 0.939583 3.06066L3.0609 0.939339ZM9.0609 6.93934L3.0609 0.939339L0.939583 3.06066L6.93958 9.06066L9.0609 6.93934Z" fill="var(--theme-color-white-text)" />
            </svg>
        </button>
        <!-- Dropdown menu -->
        <div class="z-20 bg-white divide-y divide-gray-100 rounded-lg shadow-lg w-44 overflow-y-auto absolute" :class="{ hidden: !show }">
            <ul class="py-2 text-sm text-gray-900">
                <li v-for="option in options" :key="option.id">
                    <div class="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center" @click="onOptionClicked(option)">
                        {{ option.label }}
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
const Loader = () => import("@shared/loader");

export default {
    name: "more-options",
    components: {
        Loader,
    },
    data() {
        return {
            show: false,
        };
    },
    props: {
        options: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        onClick() {
            this.show = !this.show;
        },
        onClickOutside() {
            this.show = false;
        },
        onOptionClicked(item) {
            this.$emit("onOptionClicked", item);
            this.onClickOutside();
        },
    },
};
</script>

<style lang="scss" scoped>
.loader {
    border-color: #ffffff !important;
    border-bottom-color: #0d69d5 !important;
    border-width: 3px !important;
}
</style>
